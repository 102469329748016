<template>
  <div class="header">
    <div class="wrap">
      <div class="headTop">
        <img class="logo" src="../static/logo.png" alt="" />
        <div class="menuWrap">
          <div
            :class="['menuItem', curId == item.id ? 'active' : '']"
            @click="menuClick(item)"
            v-for="item in menuList"
            :key="item.id"
          >
            {{ item.name }}
          </div>
          <a class="menuItem" href="http://qs.hfqsyl.com/#/index1"
            >AI皮肤诊断</a
          >
        </div>
        <!-- <div class="btnWrap">获取免费应用程序</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        { id: 0, name: "公司介绍", path: "index1" },
        { id: 1, name: "服务中心", path: "index2child1" },
        { id: 2, name: "系统中心", path: "index3child1" },
        { id: 3, name: "产品中心", path: "index4child1" },
        { id: 4, name: "新闻中心", path: "index5child1" },
        { id: 5, name: "资质荣誉", path: "index6" },
        { id: 6, name: "联系我们", path: "index7" },
      ],
      curId: 0,
    };
  },
  methods: {
    menuClick(e) {
      this.curId = e.id;
      this.$router.push({ name: e.path });
      sessionStorage.setItem("id", e.id);
    },
  },
  created() {
    let id = sessionStorage.getItem("id");
    if (id) {
      this.curId = id;
    } else {
      this.curId = 0;
    }
  },
  beforeDestroy() {
    sessionStorage.setItem("id", 0);
  },
};
</script>

<style lang="less" scoped>
.wrap {
  // position: fixed;
  // top: 0;
  z-index: 999;
  height: 100px;
  width: 100vw;
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3%;
  box-sizing: border-box;
  margin-bottom: 100px;
  .headTop {
    height: 70px;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    border-radius: 35px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      height: 40px;
      margin-right: 100px;
    }
    .menuWrap {
      flex: 1;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .menuItem {
        font-size: 16px;
        font-weight: bold;
        margin: 0 20px;
        cursor: pointer;
        color: #f75005;
        text-decoration: none;
      }
    }
    .btnWrap {
      height: 52px;
      width: 200px;
      border-radius: 26px;
      border: 2px solid #f75005;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f75005;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
    .btnWrap:hover {
      background: #f75005;
      color: #fff;
      transition: background-color 0.3s linear;
    }
  }
}
</style>
