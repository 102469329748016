import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
    children: [
      {
        path: "index1",
        name: "index1",
        component: () => import("../views/index1.vue"),
        meta: { show: true },
      },
      {
        path: "index2",
        name: "index2",
        component: () => import("../views/index2.vue"),
        children: [
          {
            path: "index2child1",
            name: "index2child1",
            component: () => import("../views/index2child1.vue"),
          },
          {
            path: "index2child2",
            name: "index2child2",
            component: () => import("../views/index2child2.vue"),
          },
        ],
      },
      {
        path: "index3",
        name: "index3",
        component: () => import("../views/index3.vue"),
        children: [
          {
            path: "index3child1",
            name: "index3child1",
            component: () => import("../views/index3child1.vue"),
          },
          {
            path: "index3child2",
            name: "index3child2",
            component: () => import("../views/index3child2.vue"),
          },
        ],
      },
      {
        path: "index4",
        name: "index4",
        component: () => import("../views/index4.vue"),
        children: [
          {
            path: "index4child1",
            name: "index4child1",
            component: () => import("../views/index4child1.vue"),
          },
          {
            path: "index4child2",
            name: "index4child2",
            component: () => import("../views/index4child2.vue"),
          },
        ],
      },
      {
        path: "index5",
        name: "index5",
        component: () => import("../views/index5.vue"),
        children: [
          {
            path: "index5child1",
            name: "index5child1",
            component: () => import("../views/index5child1.vue"),
          },
          {
            path: "index5child2",
            name: "index5child2",
            component: () => import("../views/index5child2.vue"),
          },
        ],
      },

      {
        path: "index6",
        name: "index6",
        component: () => import("../views/index6.vue"),
      },
      {
        path: "index7",
        name: "index7",
        component: () => import("../views/index7.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
