import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/global.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueLazyLoad from "vue-lazyload";

import axios from "axios";

Vue.prototype.$axios = axios;

Vue.use(VueLazyLoad, {
  error: "", // 加载错误的图片
  loading: require("./static/loading.jpg"), // 加载时的图片
});
Vue.use(ElementUI);

// import progressive from "progressive-image/dist/vue"; // 渐进式
// Vue.use(progressive, {
//   removePreview: true,
//   scale: true,
// });
Vue.config.productionTip = false;

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
new Vue({
  router,
  store,
  axios,
  render: (h) => h(App),
}).$mount("#app");
